
export default {
  name: 'BannerFull',
  props:{
    banner: {
      type: Object,
      default() {}
    }
  }
}
